import {
  IProfileLizenzInfo,
  PACKAGES,
  TerminStatusType,
  FEATURES,
  IPercentage,
  IPostgresVersion,
  IImportInfo,
  IProfileLizenzInfoOld,
  ANAMNESEFEATURES,
  DOCUMENTSFEATURES,
  FACTORINGFEATURES,
  ShortFeaturesMap,
  IFeatureShortItem,
  IAdminDashBoardItemBase,
  IProfile,
  R4CFEATURES,
  IMetricsUsage,
  AbrechnungsArt,
  BemaGozType,
  BenchmarkConfigUnitType,
  prettyCharlyFileName as prettyCharlyFileName_,
  extractCharlyDateFromFileName as extractCharlyDateFromFileName_,
} from '../../../types';

export function sql(strings: TemplateStringsArray, ...values: readonly unknown[]): string {
  return String.raw(strings, ...values);
}

// export function sql(strings: TemplateStringsArray, ...values: readonly unknown[]): string {
//   // First, create the full string with placeholders and values
//   let fullString = String.raw(strings, ...values);

//   // Split the string into lines
//   let lines = fullString.split('\n');

//   // Find the number of leading whitespaces in the first line

//   let firstLineWhitespace = (lines[0].match(/^\s*/) || [''])[0].length;

//   console.log(lines);

//   // Remove that amount of leading whitespaces from all lines
//   let formattedLines = lines.map(line => line.substring(firstLineWhitespace));

//   // Join the lines back into a full string and return
//   return formattedLines.join('\n');
// }

export function hasR4cDocumentsFeature(profile?: IProfileLizenzInfo): boolean {
  return hasAnyFeature(profile, ...DOCUMENTSFEATURES, FEATURES.ANAMNESE_DIAMOND);
}

export function hasMetricsFeature(profile?: IProfileLizenzInfo): boolean {
  return hasAnyFeature(profile, FEATURES.METRICS);
}

export function hasAnamneseFeature(profile?: IProfileLizenzInfo): boolean {
  return hasAnyFeature(profile, ...ANAMNESEFEATURES);
}

export function hasR4cFactoringFeature(profile?: IProfileLizenzInfo): boolean {
  return hasAnyFeature(profile, ...FACTORINGFEATURES);
}

export function hasAnyR4cTabletFeature(profile?: IProfileLizenzInfo): boolean {
  return hasAnyFeature(profile, FEATURES.FACTORINGEWE, ...DOCUMENTSFEATURES, ...ANAMNESEFEATURES);
}

export function hasFeature(profile?: IProfileLizenzInfo, feature?: FEATURES): boolean {
  if (!profile?.lizenzen || !feature) {
    return false;
  }
  return profile?.lizenzen?.indexOf(feature) >= 0;
}

export function hasAnyR4cFeature(profile?: IProfileLizenzInfo): boolean {
  return hasAnyFeature(profile, ...R4CFEATURES);
}

export function hasAnyFeature(profile?: IProfileLizenzInfo, ...features: FEATURES[]): boolean {
  if (!profile) {
    return false;
  }
  for (const f of features) {
    if (!profile?.lizenzen) {
      return false;
    }
    if (profile?.lizenzen.indexOf(f) >= 0) {
      return true;
    }
  }
  return false;
}

export function hasAllFeatures(profile?: IProfileLizenzInfo, ...features: FEATURES[]): boolean {
  for (const f of features) {
    if (!profile?.lizenzen) {
      return false;
    }
    if (profile?.lizenzen?.indexOf(f) < 0) {
      return false;
    }
  }
  return true;
}

export function isCharlyAnalyticsCustomer(profile?: IProfileLizenzInfoOld): boolean {
  return isCharlyAnalyticsCustomerByPackage(profile?.lizenzInfo?.packageName);
}

export function isCharlyAnalyticsCustomerByPackage(packagename?: string): boolean {
  return packagename === PACKAGES.CHARLYANALYTICS;
}

export function getShortFeatures(features: FEATURES[]): IFeatureShortItem[] {
  return Array.from(
    features
      .filter(k => Object.prototype.hasOwnProperty.call(ShortFeaturesMap, k))
      .map(k => ShortFeaturesMap[k])
      .reduce((acc: IFeatureShortItem[], feature) => {
        if (!acc.some(item => item.short === feature.short)) {
          acc.push(feature);
        }
        return acc;
      }, [])
      .sort((a, b) => {
        const lengthDiff = a.short.length - b.short.length;
        if (lengthDiff !== 0) {
          return lengthDiff;
        }
        const aLower = a.short.toLowerCase();
        const bLower = b.short.toLowerCase();
        if (aLower < bLower) {
          return -1;
        }
        if (aLower > bLower) {
          return 1;
        }
        return 0;
      })
      .filter(k => k !== undefined && k !== null),
  );
}

export function hasAlarm(rolle: string, key: string): boolean {
  let notInEmpfang = [
    'assistenz',
    'kontrolliert',
    'leistungen',
    'letztePSI',
    'paRoentgen',
    'psiBefund',
    'letztePA',
    'behandlerLeistungen',
  ];
  if (rolle === 'empfang') {
    return !notInEmpfang.includes(key);
  }
  return true;
}

export function terminstatusKeyString(status: TerminStatusType): string {
  switch (status) {
    case TerminStatusType.OFFEN:
      return 'OFFEN';
    case TerminStatusType.OHNE:
      return 'OHNE';
    case TerminStatusType.OK:
      return 'OK';
    case TerminStatusType.NICHTERSCHIENEN:
      return 'NICHTERSCHIENEN';
    case TerminStatusType.KURZFRISTIG_ABGESAGT:
      return 'KURZFRISTIG_ABGESAGT';
    case TerminStatusType.FRISTGERECHT_ABGESAGT:
      return 'FRISTGERECHT_ABGESAGT';
    case TerminStatusType.PRAXIS_HAT_ABGESAGT:
      return 'PRAXIS_HAT_ABGESAGT';
    case TerminStatusType.KONTROLLIERT_BEHANDLER_OK:
      return 'KONTROLLIERT_BEHANDLER_OK';
    case TerminStatusType.KONTROLLIERT_BEHANDLER_NICHTERSCHIENEN:
      return 'KONTROLLIERT_BEHANDLER_NICHTERSCHIENEN';
    case TerminStatusType.KONTROLLIERT_BEHANDLER_KURZFRISTIG_ABGESAGT:
      return 'KONTROLLIERT_BEHANDLER_KURZFRISTIG_ABGESAGT';
    case TerminStatusType.KONTROLLIERT_BEHANDLER_FRISTGERECHT_ABGESAGT:
      return 'KONTROLLIERT_BEHANDLER_FRISTGERECHT_ABGESAGT';
    case TerminStatusType.KONTROLLIERT_BEHANDLER_PRAXIS_HAT_ABGESAGT:
      return 'KONTROLLIERT_BEHANDLER_PRAXIS_HAT_ABGESAGT';
    case TerminStatusType.KONTROLLIERT_ABRECHNUNG_OK:
      return 'KONTROLLIERT_ABRECHNUNG_OK';
    case TerminStatusType.KONTROLLIERT_ABRECHNUNG_NICHTERSCHIENEN:
      return 'KONTROLLIERT_ABRECHNUNG_NICHTERSCHIENEN';
    case TerminStatusType.KONTROLLIERT_ABRECHNUNG_KURZFRISTIG_ABGESAGT:
      return 'KONTROLLIERT_ABRECHNUNG_KURZFRISTIG_ABGESAGT';
    case TerminStatusType.KONTROLLIERT_ABRECHNUNG_FRISTGERECHT_ABGESAGT:
      return 'KONTROLLIERT_ABRECHNUNG_FRISTGERECHT_ABGESAGT';
    case TerminStatusType.KONTROLLIERT_ABRECHNUNG_PRAXIS_HAT_ABGESAGT:
      return 'KONTROLLIERT_ABRECHNUNG_PRAXIS_HAT_ABGESAGT';
    case TerminStatusType.KONTROLLIERT_ALLE_OK:
      return 'KONTROLLIERT_ALLE_OK';
    case TerminStatusType.KONTROLLIERT_ALLE_NICHTERSCHIENEN:
      return 'KONTROLLIERT_ALLE_NICHTERSCHIENEN';
    case TerminStatusType.KONTROLLIERT_ALLE_KURZFRISTIG_ABGESAGT:
      return 'KONTROLLIERT_ALLE_KURZFRISTIG_ABGESAGT';
    case TerminStatusType.KONTROLLIERT_ALLE_FRISTGERECHT_ABGESAGT:
      return 'KONTROLLIERT_ALLE_FRISTGERECHT_ABGESAGT';
    case TerminStatusType.KONTROLLIERT_ALLE_PRAXIS_HAT_ABGESAGT:
      return 'KONTROLLIERT_ALLE_PRAXIS_HAT_ABGESAGT';
    case TerminStatusType.VIRTUELL:
      return 'VIRTUELL';
    default:
      return '?';
  }
}

export function terminstatusName(
  status: TerminStatusType,
  stringForOhneStatus: string,
  mitKontrolliert = true,
): string {
  let nn = '';
  let k = '';
  switch (status) {
    case TerminStatusType.OFFEN:
      nn = 'Offen';
      break;
    case TerminStatusType.OHNE:
      nn = stringForOhneStatus ? stringForOhneStatus : '-';
      break;
    case TerminStatusType.OK:
      nn = 'OK';
      break;
    case TerminStatusType.NICHTERSCHIENEN:
      nn = 'NE';
      break;
    case TerminStatusType.KURZFRISTIG_ABGESAGT:
      nn = 'KA';
      break;
    case TerminStatusType.FRISTGERECHT_ABGESAGT:
      nn = 'FA';
      break;
    case TerminStatusType.PRAXIS_HAT_ABGESAGT:
      nn = 'PA';
      break;
    case TerminStatusType.KONTROLLIERT_BEHANDLER_OK:
      nn = 'OK';
      k = ' B';
      break;
    case TerminStatusType.KONTROLLIERT_BEHANDLER_NICHTERSCHIENEN:
      nn = 'NE';
      k = ' B';
      break;
    case TerminStatusType.KONTROLLIERT_BEHANDLER_KURZFRISTIG_ABGESAGT:
      nn = 'KA';
      k = ' B';
      break;
    case TerminStatusType.KONTROLLIERT_BEHANDLER_FRISTGERECHT_ABGESAGT:
      nn = 'FA';
      k = ' B';
      break;
    case TerminStatusType.KONTROLLIERT_BEHANDLER_PRAXIS_HAT_ABGESAGT:
      nn = 'PA';
      k = ' B';
      break;
    case TerminStatusType.KONTROLLIERT_ABRECHNUNG_OK:
      nn = 'OK';
      k = ' A';
      break;
    case TerminStatusType.KONTROLLIERT_ABRECHNUNG_NICHTERSCHIENEN:
      nn = 'NE';
      k = ' A';
      break;
    case TerminStatusType.KONTROLLIERT_ABRECHNUNG_KURZFRISTIG_ABGESAGT:
      nn = 'KA';
      k = ' A';
      break;
    case TerminStatusType.KONTROLLIERT_ABRECHNUNG_FRISTGERECHT_ABGESAGT:
      nn = 'FA';
      k = ' A';
      break;
    case TerminStatusType.KONTROLLIERT_ABRECHNUNG_PRAXIS_HAT_ABGESAGT:
      nn = 'PA';
      k = ' A';
      break;
    case TerminStatusType.KONTROLLIERT_ALLE_OK:
      nn = 'OK';
      k = ' K';
      break;
    case TerminStatusType.KONTROLLIERT_ALLE_NICHTERSCHIENEN:
      nn = 'NE';
      k = ' K';
      break;
    case TerminStatusType.KONTROLLIERT_ALLE_KURZFRISTIG_ABGESAGT:
      nn = 'KA';
      k = ' K';
      break;
    case TerminStatusType.KONTROLLIERT_ALLE_FRISTGERECHT_ABGESAGT:
      nn = 'FA';
      k = ' K';
      break;
    case TerminStatusType.KONTROLLIERT_ALLE_PRAXIS_HAT_ABGESAGT:
      nn = 'PA';
      k = ' K';
      break;
    case TerminStatusType.VIRTUELL:
      nn = 'VIRTUELL';
      break;
    default:
      nn = stringForOhneStatus ? stringForOhneStatus : '-';
  }
  return mitKontrolliert ? nn + k : nn;
}

export function terminstatusNiceName(
  status: TerminStatusType,
  stringForOhneStatus: string,
  mitKontrolliert = true,
): string {
  let nn = '';
  let k = '';
  switch (status) {
    case TerminStatusType.OFFEN:
      nn = 'Offener Termin';
      break;
    case TerminStatusType.OHNE:
      nn = stringForOhneStatus ? stringForOhneStatus : 'Kein Status';
      break;
    case TerminStatusType.OK:
      nn = 'Termin hat stattgefunden';
      break;
    case TerminStatusType.NICHTERSCHIENEN:
      nn = 'Patient ist nicht erschienen';
      break;
    case TerminStatusType.KURZFRISTIG_ABGESAGT:
      nn = 'Patient hat kurzfristig abgesagt';
      break;
    case TerminStatusType.FRISTGERECHT_ABGESAGT:
      nn = 'Patient hat fristgerecht abgesagt';
      break;
    case TerminStatusType.PRAXIS_HAT_ABGESAGT:
      nn = 'Praxis hat abgesagt';
      break;
    case TerminStatusType.KONTROLLIERT_BEHANDLER_OK:
      nn = 'Termin hat stattgefunden';
      k = ', Behandler hat kontrolliert';
      break;
    case TerminStatusType.KONTROLLIERT_BEHANDLER_NICHTERSCHIENEN:
      nn = 'Patient ist nicht erschienen';
      k = ', Behandler hat kontrolliert';
      break;
    case TerminStatusType.KONTROLLIERT_BEHANDLER_KURZFRISTIG_ABGESAGT:
      nn = 'Patient hat kurzfristig abgesagt';
      k = ', Behandler hat kontrolliert';
      break;
    case TerminStatusType.KONTROLLIERT_BEHANDLER_FRISTGERECHT_ABGESAGT:
      nn = 'Patient hat fristgerecht abgesagt';
      k = ', Behandler hat kontrolliert';
      break;
    case TerminStatusType.KONTROLLIERT_BEHANDLER_PRAXIS_HAT_ABGESAGT:
      nn = 'Praxis hat abgesagt';
      k = ', Behandler hat kontrolliert';
      break;
    case TerminStatusType.KONTROLLIERT_ABRECHNUNG_OK:
      nn = 'Termin hat stattgefunden';
      k = ', Abrechnung hat kontrolliert';
      break;
    case TerminStatusType.KONTROLLIERT_ABRECHNUNG_NICHTERSCHIENEN:
      nn = 'Patient ist nicht erschienen';
      k = ', Abrechnung hat kontrolliert';
      break;
    case TerminStatusType.KONTROLLIERT_ABRECHNUNG_KURZFRISTIG_ABGESAGT:
      nn = 'Patient hat kurzfristig abgesagt';
      k = ', Abrechnung hat kontrolliert';
      break;
    case TerminStatusType.KONTROLLIERT_ABRECHNUNG_FRISTGERECHT_ABGESAGT:
      nn = 'Patient hat fristgerecht abgesagt';
      k = ', Abrechnung hat kontrolliert';
      break;
    case TerminStatusType.KONTROLLIERT_ABRECHNUNG_PRAXIS_HAT_ABGESAGT:
      nn = 'Praxis hat abgesagt';
      k = ', Abrechnung hat kontrolliert';
      break;
    case TerminStatusType.KONTROLLIERT_ALLE_OK:
      nn = 'Termin hat stattgefunden';
      k = ', Behandler und Abrechnung haben kontrolliert';
      break;
    case TerminStatusType.KONTROLLIERT_ALLE_NICHTERSCHIENEN:
      nn = 'Patient ist nicht erschienen';
      k = ', Behandler und Abrechnung haben kontrolliert';
      break;
    case TerminStatusType.KONTROLLIERT_ALLE_KURZFRISTIG_ABGESAGT:
      nn = 'Patient hat kurzfristig abgesagt';
      k = ', Behandler und Abrechnung haben kontrolliert';
      break;
    case TerminStatusType.KONTROLLIERT_ALLE_FRISTGERECHT_ABGESAGT:
      nn = 'Patient hat fristgerecht abgesagt';
      k = ', Behandler und Abrechnung haben kontrolliert';
      break;
    case TerminStatusType.KONTROLLIERT_ALLE_PRAXIS_HAT_ABGESAGT:
      nn = 'Praxis hat abgesagt';
      k = ', Behandler und Abrechnung haben kontrolliert';
      break;
    case TerminStatusType.VIRTUELL:
      nn = 'Virtueller Termin';
    default:
      nn = stringForOhneStatus ? stringForOhneStatus : '-';
  }
  return mitKontrolliert ? nn + k : nn;
}

export const abrechnungsArten = [
  {
    name: abrechnungsArtNiceName(AbrechnungsArt.GOZ_ALLG, true),
    artName: abrechnungsArtNiceName(AbrechnungsArt.GOZ_ALLG, false),
    short: abrechnungsArtShortName(AbrechnungsArt.GOZ_ALLG),
    values: [AbrechnungsArt.GOZ_ALLG],
    typ: BemaGozType.GOZ,
  },
  {
    name: abrechnungsArtNiceName(AbrechnungsArt.GOZ_KONS, true),
    artName: abrechnungsArtNiceName(AbrechnungsArt.GOZ_KONS, false),
    short: abrechnungsArtShortName(AbrechnungsArt.GOZ_KONS),
    values: [AbrechnungsArt.GOZ_KONS, AbrechnungsArt.GOZ_CHIR],
    typ: BemaGozType.GOZ,
  },
  // {
  //   name: abrechnungsArtNiceName(AbrechnungsArt.GOZ_CHIR, true),
  //   artName: abrechnungsArtNiceName(AbrechnungsArt.GOZ_CHIR, false),
  //   short: abrechnungsArtShortName(AbrechnungsArt.GOZ_CHIR),
  //   values: AbrechnungsArt.GOZ_CHIR,
  //   typ: BemaGozType.GOZ,
  // },
  {
    name: abrechnungsArtNiceName(AbrechnungsArt.GOZ_IMPL, true),
    artName: abrechnungsArtNiceName(AbrechnungsArt.GOZ_IMPL, false),
    short: abrechnungsArtShortName(AbrechnungsArt.GOZ_IMPL),
    values: [AbrechnungsArt.GOZ_IMPL],
    typ: BemaGozType.GOZ,
  },
  {
    name: abrechnungsArtNiceName(AbrechnungsArt.GOZ_PA, true),
    artName: abrechnungsArtNiceName(AbrechnungsArt.GOZ_PA, false),
    short: abrechnungsArtShortName(AbrechnungsArt.GOZ_PA),
    values: [AbrechnungsArt.GOZ_PA],
    typ: BemaGozType.GOZ,
  },
  {
    name: abrechnungsArtNiceName(AbrechnungsArt.GOZ_FA, true),
    artName: abrechnungsArtNiceName(AbrechnungsArt.GOZ_FA, false),
    short: abrechnungsArtShortName(AbrechnungsArt.GOZ_FA),
    values: [AbrechnungsArt.GOZ_FA],
    typ: BemaGozType.GOZ,
  },
  {
    name: abrechnungsArtNiceName(AbrechnungsArt.GOZ_ZE, true),
    artName: abrechnungsArtNiceName(AbrechnungsArt.GOZ_ZE, false),
    short: abrechnungsArtShortName(AbrechnungsArt.GOZ_ZE),
    values: [AbrechnungsArt.GOZ_ZE],
    typ: BemaGozType.GOZ,
  },
  {
    name: abrechnungsArtNiceName(AbrechnungsArt.GOZ_KFO, true),
    artName: abrechnungsArtNiceName(AbrechnungsArt.GOZ_KFO, false),
    short: abrechnungsArtShortName(AbrechnungsArt.GOZ_KFO),
    values: [AbrechnungsArt.GOZ_KFO],
    typ: BemaGozType.GOZ,
  },
  {
    name: abrechnungsArtNiceName(AbrechnungsArt.GOZ_PZR, true),
    artName: abrechnungsArtNiceName(AbrechnungsArt.GOZ_PZR, false),
    short: abrechnungsArtShortName(AbrechnungsArt.GOZ_PZR),
    values: [AbrechnungsArt.GOZ_PZR],
    typ: BemaGozType.GOZ,
  },
  {
    name: abrechnungsArtNiceName(AbrechnungsArt.BEMA_ZE, true),
    artName: abrechnungsArtNiceName(AbrechnungsArt.BEMA_ZE, false),
    short: abrechnungsArtShortName(AbrechnungsArt.BEMA_ZE),
    values: [AbrechnungsArt.BEMA_ZE],
    typ: BemaGozType.BEMA,
  },
  {
    name: abrechnungsArtNiceName(AbrechnungsArt.BEMA_PA, true),
    artName: abrechnungsArtNiceName(AbrechnungsArt.BEMA_PA, false),
    short: abrechnungsArtShortName(AbrechnungsArt.BEMA_PA),
    values: [AbrechnungsArt.BEMA_PA],
    typ: BemaGozType.BEMA,
  },
  {
    name: abrechnungsArtNiceName(AbrechnungsArt.BEMA_KFO, true),
    artName: abrechnungsArtNiceName(AbrechnungsArt.BEMA_KFO, false),
    short: abrechnungsArtShortName(AbrechnungsArt.BEMA_KFO),
    values: [AbrechnungsArt.BEMA_KFO],
    typ: BemaGozType.BEMA,
  },
  {
    name: abrechnungsArtNiceName(AbrechnungsArt.BEMA_KONSCHIR, true),
    artName: abrechnungsArtNiceName(AbrechnungsArt.BEMA_KONSCHIR, false),
    short: abrechnungsArtShortName(AbrechnungsArt.BEMA_KONSCHIR),
    values: [AbrechnungsArt.BEMA_KONSCHIR, AbrechnungsArt.BEMA_IP],
    typ: BemaGozType.BEMA,
  },
  {
    name: abrechnungsArtNiceName(AbrechnungsArt.BEMA_KIEFERB, true),
    artName: abrechnungsArtNiceName(AbrechnungsArt.BEMA_KIEFERB, false),
    short: abrechnungsArtShortName(AbrechnungsArt.BEMA_KIEFERB),
    values: [AbrechnungsArt.BEMA_KIEFERB],
    typ: BemaGozType.BEMA,
  },
  // {
  //   name: abrechnungsArtNiceName(AbrechnungsArt.BEMA_IP, true),
  //   artName: abrechnungsArtNiceName(AbrechnungsArt.BEMA_IP, false),
  //   short: abrechnungsArtShortName(AbrechnungsArt.BEMA_IP),
  //   values: AbrechnungsArt.BEMA_IP,
  //   typ: BemaGozType.BEMA,
  // },
  {
    name: abrechnungsArtNiceName(AbrechnungsArt.BEMA_BESUCH, true),
    artName: abrechnungsArtNiceName(AbrechnungsArt.BEMA_BESUCH, false),
    short: abrechnungsArtShortName(AbrechnungsArt.BEMA_BESUCH),
    values: [AbrechnungsArt.BEMA_BESUCH],
    typ: BemaGozType.BEMA,
  },
  {
    name: abrechnungsArtNiceName(AbrechnungsArt.UNBEKANNT, true),
    artName: abrechnungsArtNiceName(AbrechnungsArt.UNBEKANNT, false),
    short: abrechnungsArtShortName(AbrechnungsArt.UNBEKANNT),
    values: [AbrechnungsArt.UNBEKANNT],
    typ: BemaGozType.UNKNOWN,
  },
];

export function abrechnungsArtNiceName(aa: AbrechnungsArt, withPrefix: boolean): string {
  switch (aa) {
    case AbrechnungsArt.UNBEKANNT:
      return 'Sonstige';
    case AbrechnungsArt.GOZ_ALLG:
      return withPrefix ? 'Privat Allg.' : 'Allg.';
    case AbrechnungsArt.GOZ_KONS:
      return withPrefix ? 'Privat KCh' : 'KCh';
    case AbrechnungsArt.GOZ_CHIR:
      return withPrefix ? 'Privat Ch' : 'Ch';
    case AbrechnungsArt.GOZ_IMPL:
      return withPrefix ? 'Privat Impl' : 'Impl';
    case AbrechnungsArt.GOZ_PA:
      return withPrefix ? 'Privat PA' : 'PA';
    case AbrechnungsArt.GOZ_FA:
      return withPrefix ? 'Privat FA' : 'FA';
    case AbrechnungsArt.GOZ_ZE:
      return withPrefix ? 'Privat ZE' : 'ZE';
    case AbrechnungsArt.GOZ_KFO:
      return withPrefix ? 'Privat KFO' : 'KFO';
    case AbrechnungsArt.GOZ_PZR:
      return withPrefix ? 'Privat PZR' : 'PZR';
    case AbrechnungsArt.BEMA_ZE:
      return withPrefix ? 'Kasse ZE' : 'ZE';
    case AbrechnungsArt.BEMA_PA:
      return withPrefix ? 'Kasse PA' : 'PA';
    case AbrechnungsArt.BEMA_KFO:
      return withPrefix ? 'Kasse KFO' : 'KFO';
    case AbrechnungsArt.BEMA_KONSCHIR:
      return withPrefix ? 'Kasse KCh' : 'KCh';
    case AbrechnungsArt.BEMA_KIEFERB:
      return withPrefix ? 'Kasse KBr' : 'KBr';
    case AbrechnungsArt.BEMA_IP:
      return withPrefix ? 'Kasse IP' : 'IP';
    case AbrechnungsArt.BEMA_BESUCH:
      return withPrefix ? 'Kasse Besuch' : 'Besuch';
  }
}

export function abrechnungsArtShortName(aa: AbrechnungsArt): string {
  switch (aa) {
    case AbrechnungsArt.UNBEKANNT:
      return 'Sonstige';
    case AbrechnungsArt.GOZ_ALLG:
      return 'GozAllg';
    case AbrechnungsArt.GOZ_KONS:
      return 'GozKCh';
    case AbrechnungsArt.GOZ_CHIR:
      return 'GozCh';
    case AbrechnungsArt.GOZ_IMPL:
      return 'GozImpl';
    case AbrechnungsArt.GOZ_PA:
      return 'GozPA';
    case AbrechnungsArt.GOZ_FA:
      return 'GozFA';
    case AbrechnungsArt.GOZ_ZE:
      return 'GozZE';
    case AbrechnungsArt.GOZ_KFO:
      return 'GozKFO';
    case AbrechnungsArt.GOZ_PZR:
      return 'GozPZR';
    case AbrechnungsArt.BEMA_ZE:
      return 'BemaZE';
    case AbrechnungsArt.BEMA_PA:
      return 'BemaPA';
    case AbrechnungsArt.BEMA_KFO:
      return 'BemaKFO';
    case AbrechnungsArt.BEMA_KONSCHIR:
      return 'BemaKCh';
    case AbrechnungsArt.BEMA_KIEFERB:
      return 'BemaKBr';
    case AbrechnungsArt.BEMA_IP:
      return 'BemaIP';
    case AbrechnungsArt.BEMA_BESUCH:
      return 'BemaBesuch';
  }
}

export function benchmarkUnitShortName(unit: BenchmarkConfigUnitType): string {
  switch (unit) {
    case BenchmarkConfigUnitType.PATIENTEN:
      return 'P';
    case BenchmarkConfigUnitType.TERMINE:
      return 'T';
    case BenchmarkConfigUnitType.EURO:
      return '€';
    case BenchmarkConfigUnitType.PERCENT:
      return '%';
    case BenchmarkConfigUnitType.HKP:
      return 'HKP';
    case BenchmarkConfigUnitType.HOURSMINUTES:
      return 'h:m';
    case BenchmarkConfigUnitType.DAYS:
      return 'd';
    case BenchmarkConfigUnitType.MONTHS:
      return 'M';
    case BenchmarkConfigUnitType.YEARS:
      return 'J';
    case BenchmarkConfigUnitType.MINUTES:
      return 'min';
    case BenchmarkConfigUnitType.FUELLUNGEN:
      return 'F';
    case BenchmarkConfigUnitType.FUELLUNGENPROPATIENT:
      return 'F/P';
    case BenchmarkConfigUnitType.IMPLANTATE:
      return 'I';
    case BenchmarkConfigUnitType.FAKTOR:
      return 'x';
  }
  return `${unit || ''}`;
}

export function benchmarkUnitNiceName(unit: BenchmarkConfigUnitType): string {
  switch (unit) {
    case BenchmarkConfigUnitType.PATIENTEN:
      return 'Patienten';
    case BenchmarkConfigUnitType.TERMINE:
      return 'Termine';
    case BenchmarkConfigUnitType.EURO:
      return '€';
    case BenchmarkConfigUnitType.PERCENT:
      return '%';
    case BenchmarkConfigUnitType.HKP:
      return 'HKP';
    case BenchmarkConfigUnitType.HOURSMINUTES:
      return 'h:m';
    case BenchmarkConfigUnitType.DAYS:
      return 'Tage';
    case BenchmarkConfigUnitType.MONTHS:
      return 'Monate';
    case BenchmarkConfigUnitType.YEARS:
      return 'Jahre';
    case BenchmarkConfigUnitType.MINUTES:
      return 'Minuten';
    case BenchmarkConfigUnitType.FUELLUNGEN:
      return 'Füllungen';
    case BenchmarkConfigUnitType.FUELLUNGENPROPATIENT:
      return 'Füllungen pro Patient';
    case BenchmarkConfigUnitType.IMPLANTATE:
      return 'Implantate';
    case BenchmarkConfigUnitType.FAKTOR:
      return 'x';
  }
  return `${unit}`;
}

/**
 * if multiple ui/user changes can trigger an async operation where you care about the result of the last triggered operation
 * use this method to always receive the outcome of the last operation no matter how the timings of the multiple async operations are
 *
 * usage: wrap async operation with this method and use the wrapped function
 *
 * let safeAsyncOperation = ensureLastRequestPrecedence(asynOperation);
 *
 * let promiseA = safeAsyncOperation();
 * let promiseB = safeAsyncOperation();
 *
 * await promiseA; // this will never resolve, since there was another incovation after its call
 *
 * safeAsyncOperation only resolves/rejects if there was no other invocation triggered after it.
 *
 * eg usage in components:
 * ```
 * paramsChanged() {
 *    this.loading = true;
 *    try {
 *      let data = await safeAsyncOperation(this.params);
 *    } catch(e) {
 *      alert(e);
 *    }
 *    this.loading = false;
 * }
 * ```
 *
 * @param asyncCall
 * @returns
 */
export const ensureLastRequestPrecedence = <T extends (...args: any) => any>(
  asyncCall: T,
): ((...a: Parameters<T>) => Promise<ReturnType<T>>) => {
  let lastPromise: any;

  return (...args: Parameters<T>) => {
    // eslint-disable-next-line prefer-spread
    let nowPromise: ReturnType<T> = asyncCall.apply(null, args);
    lastPromise = nowPromise;

    // eslint-disable-next-line @typescript-eslint/no-misused-promises, no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        // eslint-disable-next-line @typescript-eslint/await-thenable
        let res = await nowPromise;
        if (lastPromise === nowPromise) {
          resolve(res);
        }
      } catch (e) {
        if (lastPromise === nowPromise) {
          reject(e);
        }
      }
    });
  };
};

export function sleep(ms = 0): Promise<void> {
  return new Promise<void>(r => setTimeout(r, ms));
}

export function isTrue(thing: any) {
  if (typeof thing === 'boolean') {
    return thing === true;
  }
  if (typeof thing === 'string') {
    let thingLow = thing.toLowerCase();
    return thingLow === 't' || thingLow === 'true' || thingLow === '1';
  }
  if (typeof thing === 'number') {
    return thing === 1;
  }
  return false;
}

export function jaNein(toggle: boolean, ja = 'Ja', nein = 'Nein'): string {
  return toggle ? ja : nein;
}

export function createPercentage(count: number | undefined, total: number, roundingPrecision = -1): IPercentage {
  if (!count) {
    return { count: 0, percentage: 0 };
  }
  return { count, percentage: getPercentage(count, total, roundingPrecision) };
}

export function getPercentage(count: number, total: number, roundingPrecision = -1): number {
  let p = (count / total) * 100;
  return round(p, roundingPrecision);
}

export function round(n: number, precision: number): number {
  if (precision > 0) {
    let factor = Math.pow(10, precision);
    let tempNumber = n * factor;
    let roundedTempNumber = Math.round(tempNumber);
    return roundedTempNumber / factor;
  }
  return n;
}

export function parsePostgresVersion(v: string): IPostgresVersion {
  if (!v || !v.includes(',') || !v.includes('.')) {
    return {
      raw: v,
      short: v,
      major: '?',
      minor: '?',
      patch: '?',
    };
  }

  const vshortRaw = v.toLowerCase().replaceAll('postgresql', '').trim();
  const [precomma] = vshortRaw.split(',');
  const [vshort] = precomma.split(' ');
  const [major, minor, patch] = vshort.split('.');
  return {
    raw: v,
    short: vshort,
    major,
    minor,
    patch,
  };
}

export let TASK_PREFIX_IMPORT = 'I';
export let TASK_PREFIX_TRIGGER = 'T';

export function taskIdFromImport(info: IImportInfo | undefined) {
  if (info) {
    return `${TASK_PREFIX_IMPORT}:${info.incremental ? 'INC' : 'FULL'}:${info.cid}:${info.id}`;
  }
  return undefined;
}

export function taskIdForTriggerRaw(cid: string, type: string, id: string) {
  return `${TASK_PREFIX_TRIGGER}:${cid}:${type}:${id}`;
}

export function isImportTask(taskId: string) {
  return taskId.startsWith(TASK_PREFIX_IMPORT);
}

export function isTriggerTask(taskId: string) {
  return taskId.startsWith(TASK_PREFIX_TRIGGER);
}

/**
 * NOT secure hash function
 * credits: https://stackoverflow.com/questions/6122571/simple-non-secure-hash-function-for-javascript
 */
export function simpleHashCode(inputString: string) {
  let hash = 0;
  if (inputString.length === 0) {
    return hash;
  }
  for (let i = 0; i < inputString.length; i++) {
    let char = inputString.charCodeAt(i);
    // tslint:disable-next-line
    hash = (hash << 5) - hash + char;
    // tslint:disable-next-line
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}

export const prettyCharlyFileName = prettyCharlyFileName_;
export const extractCharlyDateFromFileName = extractCharlyDateFromFileName_;

export function sanitizeFilenameForCharly(fileName: string) {
  let replacementMap: { [key: string]: string } = {
    ä: 'ae',
    ö: 'oe',
    ü: 'ue',
    ß: 'ss',
    Ä: 'Ae',
    Ö: 'Oe',
    Ü: 'Ue',
    '*': 's',
    '+': 'p',
    ' ': '_',
  };
  return (
    fileName
      .split('')
      // replace problematic characters
      .map(char => replacementMap[char] || char)
      // only allow these characters
      // disallow # since it causes problems with the document preview in charly mac installations
      // disallow äöüÄÖÜ because it chauses problems in .inputname files
      .filter(char => /[a-zA-Z_.0-9-]/.test(char))
      .join('')
  );
}

// we need this temporarly to create stochwort version which can be used as column name in the database
export function slugifyStichwort(stichwort: string) {
  if (!stichwort) {
    return 'UNBEKANNT';
  }
  const ss = sanitizeFilenameForCharly(stichwort).replaceAll('.', '_') || '??';
  return ss[0].toLocaleUpperCase() + ss.substring(1);
}

export type UnpackPromise<T> = T extends PromiseLike<infer U> ? U : T;

export type UnpackPromiseObject<T> = { [P in keyof T]: UnpackPromise<T[P]> };

export type PackPromise<T> = { [P in keyof T]: PromiseLike<T[P]> };

/**
 * Creates a Promise that is resolved with an object of results when all of the provided Promises
 * resolve, or rejected when any Promise is rejected.
 * @param promiseMap An object of Promises.
 * @returns A new Promise.
 */
export async function promiseAllMap<T extends { [key: string]: PromiseLike<any> }>(
  promiseMap: T,
): Promise<UnpackPromiseObject<T>> {
  let resultsArray = await Promise.all(Object.values(promiseMap));
  let resultObj = {} as UnpackPromiseObject<T>;

  Object.keys(promiseMap).forEach((key: keyof typeof promiseMap, i) => {
    resultObj[key] = resultsArray[i];
  });
  return resultObj;
}

// get years easter date
export function ostersonntag(jahr: number) {
  const a = jahr % 19;
  const b = Math.floor(jahr / 100);
  const c = jahr % 100;
  const d = Math.floor(b / 4);
  const e = b % 4;
  const f = Math.floor((b + 8) / 25);
  const g = Math.floor((b - f + 1) / 3);
  const h = (19 * a + b - d - g + 15) % 30;
  const i = Math.floor(c / 4);
  const k = c % 4;
  const l = (32 + 2 * e + 2 * i - h - k) % 7;
  const m = Math.floor((a + 11 * h + 22 * l) / 451);
  const n = Math.floor((h + l - 7 * m + 114) / 31);
  const p = (h + l - 7 * m + 114) % 31;
  return { jahr, monat: n, tag: p + 1 };
}

export function osterSonntagAsUTCDate(jahr: number) {
  const o = ostersonntag(jahr);
  return new Date(Date.UTC(jahr, o.monat - 1, o.tag));
}

export function createEmptyDashBoardData(profile: IProfile): IAdminDashBoardItemBase {
  return {
    cid: profile.cid,
    clientName: profile.praxisKurzName,
    lastFullImport: undefined,
    lastIncrementalImport: undefined,
    firstAppointment: undefined,
    lastAppointment: undefined,
    clientActivity: {
      metrics: {
        lastmonth: { sum: -1, chart: [] },
        lastyear: { sum: -1, chart: [] },
      },
      r4c: {
        factoring: {
          lastmonth: { sum: -1, chart: [] },
          lastyear: { sum: -1, chart: [] },
        },
        documents: {
          lastmonth: { sum: -1, chart: [] },
          lastyear: { sum: -1, chart: [] },
        },
        anamnese: {
          lastmonth: { sum: -1, chart: [] },
          lastyear: { sum: -1, chart: [] },
        },
      },
    },
    aktivePatienten: -1,
    neuPatienten: -1,
    umsatzLetztesJahr: -1,
    umsatzAktuellesJahr: -1,
    termineLetztesJahr: -1,
    termineAktuellesJahr: -1,
    angeboteneBehandlungszeitAktuellesJahr: -1,
    angeboteneBehandlungszeitLetztesJahr: -1,
    anzahlPatienten: -1,
    anzahlBenutzer: -1,
    anzahlTermine: -1,
    anzahlZimmer: -1,
    anzahlBehandler: { za: -1, pzr: -1 },
    aktiveBehandler: {
      cid: '',
      monat: '',
      za: -1,
      pzr: -1,
      billable: -1,
      billableRounded: -1,
      raw: {} as IMetricsUsage,
    },
    lastActive: undefined,
    lastActiveUsers: undefined,
    realMitarbeiter: undefined,
    importStepDurations: undefined,
    fullImportStatistics: undefined,
    incrementalImportStatistics: undefined,
    dbInfo: undefined,
    pvsInfo: { version: '-' },
    screening: { kzv: '-', laborbel: '-' },
    stripe: { metricsBehandlerLizenzen: 0, metricsType: 'none', mrr: 0, products: [] },
    collected: new Date(),
    heuteScore: {
      empfang: {
        avg: 0,
        chart: [],
      },
      prophylaxe: {
        avg: 0,
        chart: [],
      },
      zahnarzt: {
        avg: 0,
        chart: [],
      },
      abrechnung: {
        avg: 0,
        chart: [],
      },
      gesamt: {
        avg: 0,
        chart: [],
      },
    },
    abrechnungsScore: {
      currentQuarter: undefined,
      lastQuarter: undefined,
    },
    hkpCounts: {
      genehmigt: -1,
      ohneTermin: -1,
      genehmigtOhneTermin: -1,
    },
  };
}

/**
 * check for overlapping of two date ranges
 * @param r1from date range 1 from
 * @param r1to date range 1 to
 * @param r2from date range 2 from
 * @param r2to date range 2 to
 * @returns if the two date ranges overlap
 */
export function dateRangeOverlap(
  r1from: Date,
  r1to: Date,
  r2from: Date,
  r2to: Date,
): { overlap?: boolean; ok: boolean; error?: string; case?: string } {
  // some checks
  if (!r1from || !r1to || !r2from || !r2to) {
    return { ok: false, error: 'one or more dates are undefined' };
  }
  if (r1from.valueOf() > r1to.valueOf() || r2from.valueOf() > r2to.valueOf()) {
    return { ok: false, error: 'a from date is after to date' };
  }
  // check if r1 is before r2
  if (r1to.valueOf() < r2from.valueOf()) {
    return { ok: true, overlap: false, case: 'r1to < r2from' };
  }
  // check if r2 is before r1
  if (r2to.valueOf() < r1from.valueOf()) {
    return { ok: true, overlap: false, case: 'r2to < r1from' };
  }
  // r1 includes r2
  if (r1from.valueOf() <= r2from.valueOf() && r1to.valueOf() >= r2to.valueOf()) {
    return { ok: true, overlap: true, case: 'r1 includes r2' };
  }
  // r2 includes r1
  if (r2from.valueOf() <= r1from.valueOf() && r2to.valueOf() >= r1to.valueOf()) {
    return { ok: true, overlap: true, case: 'r2 includes r1' };
  }
  // overlap
  if (
    (r1to.valueOf() >= r2to.valueOf() && r1from.valueOf() >= r2from.valueOf()) ||
    (r2to.valueOf() >= r1to.valueOf() && r1from.valueOf() >= r1from.valueOf())
  ) {
    return { ok: true, overlap: true, case: 'overlap' };
  }
  return { ok: true, overlap: false, case: 'unknown' };
}

export function mapObject<T>(
  obj: T,
  keyMapper: (origkey: string, origvalue?: any) => string,
  valueMapper: (origvalue: any, origkey?: string) => any,
): { [key: string]: any } {
  const ret: any = {};
  if (obj) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const v = obj[key];
        const mappedValue = valueMapper(v, key);
        if (mappedValue !== undefined) {
          ret[keyMapper(key, v)] = mappedValue;
        }
      }
    }
  }
  return ret;
}

export function logObjectModificationsProxy(targetObj: any) {
  // Helper function to recursively wrap objects or arrays in proxies
  function wrapObject(obj: any) {
    if (typeof obj !== 'object' || obj === null || obj.__isProxy) {
      return obj; // Return if it's not an object or already proxied
    }

    // Proxy handler to log property modifications
    const handler = {
      get(target: any, property: string) {
        return target[property]; // Simply return the property value
      },
      set(target: any, property: string, value: any) {
        console.log(`--> Property '${property}' set to`, value);
        target[property] = wrapObject(value); // Wrap new value if it is an object
        return true;
      },
      deleteProperty(target: any, property: string) {
        console.log(`--> Property '${property}' deleted`);
        return Reflect.deleteProperty(target, property);
      },
    };

    // Recursively wrap each nested object or array in a proxy
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        obj[key] = wrapObject(obj[key]);
      }
    }

    // Mark the object as a proxy to prevent re-wrapping
    Object.defineProperty(obj, '__isProxy', {
      value: true,
      enumerable: false,
      configurable: false,
      writable: false,
    });

    // Return the proxy-wrapped object
    return new Proxy(obj, handler);
  }

  return wrapObject(targetObj); // Start the wrapping process
}
